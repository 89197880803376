export const menus = [
  { name: 'Styles', href: '/#styles' },
  { name: 'Examples', href: '/#examples' },
  { name: 'How it Works', href: '/#how-it-works' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'FAQ', href: '/#faq' },
];

export const menusTShirt = [
  { name: 'Examples', href: '/lp/t-shirt/#examples' },
  { name: 'How it Works', href: '/lp/t-shirt/#how-it-works' },
  { name: 'Customers', href: '/lp/t-shirt/#customers' },
  { name: 'FAQ', href: '/lp/t-shirt/#faq' },
];
